import React from 'react';
import { RichText, Text, withSitecoreContext, Image } from '@sitecore-jss/sitecore-jss-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carouselProductOver.scss';
import './productSummary.scss';
import ProductRating from '../ProductRating';
import i18next from 'i18next';
import ReactComponent from '../../ReactComponent';
import ProductImagesGalleryV2 from '../ProductImagesGalleryV2';
import ProductReviews from '../../utils/ProductReviews';
import ProductPIL from '../ProductPIL';
import { handleClickToBuy, handleWriteReview } from '../Tagging';

class ProductSummary extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      currLang: this.props.sitecoreContext.language,
    };
  }

  componentDidMount() {
    // scroll fix
    window.scrollTo(0, 0);
  }

  handleToggleAccordion(e) {
    const selectedParent = e.currentTarget.parentElement.parentElement.parentElement;
    selectedParent.classList.toggle('open');
    document.querySelectorAll('.card').forEach((elem) => {
      if (elem !== selectedParent) elem.classList.remove('open');
    });
  }

  render() {
    const productData = this.props.sitecoreContext.route.fields;

    return (
      <div className="row_custom_small summary-container">
        <div className="col-sm-7 col-xl-6 entry-image carousel_custom_product_5">
          <ProductImagesGalleryV2 />
        </div>
        <React.Fragment>
          <div className="col-sm-5 col-xl-6 entry-summary carousel_custom_product_7">
            <div className="summary">
              <h1 className="product_title entry-title">
                <Text field={productData && productData.title} />
              </h1>
              <div className="product-excerpt">
                <RichText tag="p" field={productData && productData.productShortDescription} />
              </div>
              <div className="commerce-product-rating">
                <div className="row">
                  <div className="rating col-12 pl-0 pt-0">
                    {parseInt(productData.rating.value) !== 0 && (
                      <div style={{ display: 'inline-flex', marginRight: '20px' }}>
                        <ProductRating productid={productData} /> &nbsp;
                        <ProductReviews productId={this.props.sitecoreContext.itemId} />
                      </div>
                    )}
                    <a
                      onClick={() => handleWriteReview(productData.title.value, productData.codeEan.value)}
                      href="#review_form_wrapper"
                      style={{ textDecoration: 'underline' }}>
                      Ajouter un commentaire
                    </a>
                  </div>
                </div>
              </div>
              <div className="row buy-btn-ctn">
                <a
                  onClick={() => handleClickToBuy(productData.title.value, productData.codeEan.value)}
                  data-eans={productData.codeEan.value}
                  className="swn-tag-wtb-btn buy-btn">
                  {i18next.t('Acheter')}
                </a>
              </div>

              <div className="accordion" id="product_accordion">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0 mt-0 mt-0">
                      <button
                        onClick={(e) => this.handleToggleAccordion(e)}
                        className="btn btn-link collapsed text-uppercase text-left mr-2"
                        type="button"
                        data-toggle="collapse"
                        data-target="#Details"
                        aria-expanded="false"
                        aria-controls="Details">
                        {i18next.t('DetailsLabel')}
                      </button>
                    </h2>
                  </div>
                  <div id="Details" className="collapse" aria-labelledby="headingOne" data-parent="#product_accordion">
                    <div className="card-body">
                      <span className="double_cat">
                        <RichText field={productData && productData.productLongDescription} tag="span" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0 mt-0">
                      <button
                        onClick={(e) => this.handleToggleAccordion(e)}
                        className="btn btn-link collapsed text-uppercase text-left mr-2"
                        type="button"
                        data-toggle="collapse"
                        data-target="#use"
                        aria-expanded="false"
                        aria-controls="use">
                        {i18next.t('HowToUseLabel')}
                      </button>
                    </h2>
                  </div>
                  <div id="use" className="collapse" aria-labelledby="headingThree" data-parent="#product_accordion">
                    <div className="card-body">
                      <RichText field={productData && productData.howtouse} />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0 mt-0">
                      <button
                        onClick={(e) => this.handleToggleAccordion(e)}
                        className="btn btn-link collapsed text-uppercase text-left mr-2"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ingredient"
                        aria-expanded="false"
                        aria-controls="ingredient">
                        {i18next.t('IngredientsLabel')}
                      </button>
                    </h2>
                  </div>
                  <div id="ingredient" className="collapse" aria-labelledby="headingThree" data-parent="#product_accordion">
                    <div className="card-body">
                      <RichText field={productData && productData.ingredientsText} />
                    </div>
                  </div>
                </div>
                {/*---------------- PIL -------------*/}
                {this.props.sitecoreContext.route.fields.GlobalFootprintScore.value && (
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0 mt-0">
                        <button
                          onClick={(e) => this.handleToggleAccordion(e)}
                          className="btn btn-link collapsed text-uppercase text-left mr-2"
                          type="button"
                          data-toggle="collapse"
                          data-target="#pil"
                          aria-expanded="false"
                          aria-controls="pil">
                          {i18next.t('PIL-general-title')}
                        </button>
                      </h2>
                    </div>
                    <div id="pil" className="collapse" aria-labelledby="headingFour" data-parent="#product_accordion">
                      <div className="card-body">
                        <ProductPIL />
                      </div>
                    </div>
                  </div>
                )}
                {productData && productData.environmentText.value !== '' && (
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0 mt-0">
                        <button
                          onClick={(e) => this.handleToggleAccordion(e)}
                          className="btn btn-link collapsed text-uppercase text-left mr-2"
                          type="button"
                          data-toggle="collapse"
                          data-target="#environment"
                          aria-expanded="false"
                          aria-controls="environment">
                          {i18next.t('EnvironmentLabel')}
                        </button>
                      </h2>
                    </div>
                    <div id="environment" className="collapse" aria-labelledby="headingFour" data-parent="#product_accordion">
                      <div className="card-body">
                        <RichText field={productData && productData.environmentText} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
        {productData.productIngredients.length !== 0 && (
          <div className={'section featuredPosts leftWhite featuredProductPage'}>
            <div className="first-part">
              <div className="row">
                <div className="contentTitle">
                  <RichText field={productData && productData.ingredientTitle} tag={'h2'} className={'title'} />
                </div>
              </div>
            </div>
            <div className="second-part">
              <div className="row">
                {productData.productIngredients &&
                  productData.productIngredients.map((listItem, index) => (
                    <div className="contents" key={`sharedListItem-${index}`}>
                      <div className="text-center">
                        <Image loading="lazy" field={listItem.fields.Image} />
                      </div>
                      <Text field={listItem.fields.Title} tag={'h4'} className={'title'} />
                      <RichText field={listItem.fields.FullDescription} tag={'div'} className={'content'} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withSitecoreContext()(ProductSummary);
